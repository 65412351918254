/*=======================================================================
1. Default Styles
=========================================================================*/
@import "default/variable";
@import "default/reset";
@import "default/typography";
@import "default/form";
@import "default/classes";

/*=======================================================================
2. Elements Styles
=========================================================================*/
@import "elements/animate";
@import "elements/back-top";
@import "elements/breadcrumb";
@import "elements/button";
@import "elements/pagination";
@import "elements/section-heading";
@import "elements/slick";

/*=======================================================================
3. Template Styles
=========================================================================*/
@import "template/about";
@import "template/banner";
@import "template/brand";
@import "template/call-to-action";
@import "template/case-study";
@import "template/contact";
@import "template/counterup";
@import "template/faq";
@import "template/pricing";
@import "template/process";
@import "template/project";
@import "template/service";
@import "template/team";
@import "template/testimonial";
@import "template/why-choose";
@import "template/common-pages";
@import "template/onepage";

/*=======================================================================
4.Blocks Styles 
=========================================================================*/
@import "header/header";
@import "blog/blog";
@import "blog/widget";
@import "footer/footer";

/*=======================================================================
5. Dark Style 
=========================================================================*/
@import "dark-style/dark-version";

/*=======================================================================
6. Spacing 
=========================================================================*/
@import "default/spacing";

/*=======================================================================
7. RTL 
=========================================================================*/
// @import "rtl/rtl";


// .portfolio-swiper {
//     .swiper {
//       width: 100%;
//       height: 276px;
//       display: flex;
//       justify-content: center;
//     }
  
//     .swiper-wrapper {
//       margin-left: -100px;
//     }
  
//     .swiper-slide {
//       // text-align: center;
//       font-size: 18px;
//       /* Center slide text vertically */
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: -webkit-flex;
//       display: flex;
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       -webkit-justify-content: center;
//       justify-content: center;
//       -webkit-box-align: center;
//       -ms-flex-align: center;
//       -webkit-align-items: center;
//       align-items: center;
//     }
  
//     .swiper-slide.swiper-slide-active {
//       width: 280px !important;
//       // display: flex;
//       // align-items: center;
//       // justify-content: center;
//     }

//     @media #{$xs-mobile} {
        
//         .swiper-wrapper {
//           margin-left: -70px !important;
//         }
//         .swiper-slide.swiper-slide-active {
//           width: 200px !important;
//           // display: flex;
//           // align-items: center;
//           // justify-content: center;
//         }
//     }
// }

.shadow-portfolio {
    box-shadow: 2.41026px 9.05128px 9.05128px rgba(0, 0, 0, 0.5), -2.41026px 0px 9.05128px rgba(0, 0, 0, 0.5)
}

.blog-share_new_test {
    display: flex;
    align-items: center;
    background-color: var(--color-primary);
    padding: 24px 30px;
    border-radius: 16px;
    @media #{$smlg-device} {
        padding: 24px 20px;
    }
    .title {
        color: var(--color-white);
        margin-bottom: 0;
        margin-right: 25px;
        @media #{$smlg-device} {
            margin-right: 20px;
        }
    }
    li{
        display: inline-block;
        a {
            color: var(--color-white);
            padding: 5px 10px;
            @media #{$smlg-device} {
                padding: 5px;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
}