.scrollToTop{
    border-radius: 50% !important;
    background-color: #5956E9 !important;
}

.pointer {
    cursor: pointer;
}

.contactAddress {
    cursor: pointer;
}

.contactAddress:hover {
    color: #5956E9 !important;
}

.customAnimation_slideInLeft {
    animation: slideInLeftCustom 2.5s;
}

@keyframes slideInLeftCustom {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.faq-result-count {
    font-size: smaller;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 10px;
}

.active-dark-mode .faq-accordion .accordion-button.collapsed::after{
    background-color: #D3D3D4 !important;
    border-radius: 50% !important;
}

/* [1] The container */
.img-hover-zoom_contact {
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    border-radius: 2rem !important;
  }
  
  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom_contact img {
    transition: transform .5s ease;
  }
  
  /* [3] Finally, transforming the image when container gets hovered */
  .img-hover-zoom_contact:hover img {
    transform: scale(1.1);
  }

  .about_us_banner_main_image {
    bottom: 10% !important;
    right: -4% !important;
    transform: translateY(20%) !important;
  }

@media (max-width: 991px) {
    .about_us_banner_main_image {
    bottom: 10% !important;
    right: -4% !important;
    transform: translateY(5%) !important;
    }
}

/* Services Page */
.stick-to-top {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 50 !important;
    /* animation: my_fadeInUp_custom 0s ease-in-out; */
}

/* @keyframes my_fadeInUp_custom {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
} */

.swiper {
    width: 100%;
}
  
/* for screens abpve 992 */
@media (min-width: 992px) {
    .swiper {
        width: 100%;
    }
}

.portfolio_swiper_active {
    margin: 0 6px !important;
}
  
.swiper-slide {
    /* text-align: center; */
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
  
.swiper-slide .non_mobile {
    display: block;
    width: 95%;
    object-fit: cover;
}